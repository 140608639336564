import React from "react";
import { Box, Heading } from "@chakra-ui/core";

const IndexPage = () => {
  return (
    <Box p={5}>
      <Heading size="md" mb={5}>
        404
      </Heading>
    </Box>
  );
};

export default IndexPage;